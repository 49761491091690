<template>
  <a
    target="_blank"
    :href="GET_STARTED_LINK"
    class="btn bg-primary text-white rounded rounded-5 px-5"
    >{{ t("common.buttons.get-started") }}</a
  >
</template>

<script setup lang="ts">
import { GET_STARTED_LINK } from "@/constants/get-started-link";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style scoped></style>
