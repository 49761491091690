<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-secondary sticky-top">
    <div class="container">
      <a class="navbar-brand" href="/"
        ><img src="../../assets/images/logo.svg" alt="Logo"
      /></a>
      <div class="d-flex align-items-center justify-content-center gap-2">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <LanguageSwitcher class="d-lg-none" />
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li
            class="nav-item text-center me-lg-5"
            v-for="(link, i) in navbarLinks"
            :key="i"
          >
            <router-link class="nav-link" aria-current="page" :to="link.to">
              {{ t(link.name) }}
            </router-link>
          </li>
        </ul>
        <div class="d-flex justify-content-center align-items-center gap-2">
          <GetStartedButton />
          <LanguageSwitcher class="d-none d-lg-block" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import GetStartedButton from "@/components/base-components/buttons/GetStartedButton.vue";
import { useNavigationLinks } from "@/composables/navigationLinks";
import LanguageSwitcher from "@/components/misc/language-switcher/LanguageSwitcher.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { navbarLinks } = useNavigationLinks();
</script>

<style scoped></style>
