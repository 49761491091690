import { RouterLinkInterface } from "@/interfaces/RouterLinkInterface";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export const useNavigationLinks = () => {
  const { locale } = useI18n();

  const termsAndConditionsLink = computed(() => {
    switch (locale.value) {
      case "en":
        return "/legal-pages/en/terms-and-conditions.pdf";
      case "al":
        return "/legal-pages/al/termat-dhe-kushtet-e-përgjithshme.pdf";
      default:
        return "/legal-pages/mk/услови-и-правила.pdf";
    }
  });

  const refundPolicyLink = computed(() => {
    switch (locale.value) {
      case "en":
        return "/legal-pages/en/refund-policy.pdf";
      case "al":
        return "/legal-pages/al/politika-e-rifundit.pdf";
      default:
        return "/legal-pages/mk/политика-за-рефундирање.pdf";
    }
  });

  const privacyPolicyLink = computed(() => {
    switch (locale.value) {
      case "en":
        return "/legal-pages/en/privacy-policy.pdf";
      case "al":
        return "/legal-pages/al/politika-e-privatësisë.pdf";
      default:
        return "/legal-pages/mk/политика-на-приватност.pdf";
    }
  });

  const legalPagesLinks: RouterLinkInterface[] = [
    {
      name: "common.links.terms-and-conditions",
      to: termsAndConditionsLink.value,
    },
    {
      name: "common.links.privacy-policy",
      to: privacyPolicyLink.value,
    },
    {
      name: "common.links.refund-policy",
      to: refundPolicyLink.value,
    },
  ];

  const navbarLinks: RouterLinkInterface[] = [
    {
      name: "common.links.contact",
      to: "/contact",
    },
  ];

  return {
    legalPagesLinks,
    navbarLinks,
    termsAndConditionsLink,
    refundPolicyLink,
    privacyPolicyLink,
  };
};
