<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle border-1 border-white rounded-4"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img :src="selectedLocale.flag" alt="Flag" />
    </button>
    <ul class="dropdown-menu dropdown-menu-end bg-dark">
      <li
        @click="changeLocale(loc.value)"
        v-for="loc in availableLocales"
        :key="loc.value"
        class="mb-2 text-center cursor-pointer"
      >
        <img :src="loc.flag" alt="Flag" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useLanguageSwitcher } from "@/composables/languageSwitcher";

const { changeLocale, selectedLocale, availableLocales } =
  useLanguageSwitcher();
</script>

<style scoped>
.dropdown-menu {
  width: 20px !important;
}
</style>
