<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | AiCharge` : `AiCharge`
    }}</template>
  </metainfo>
  <router-view></router-view>
</template>

<style lang="scss"></style>
