import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DefaultLayout from "@/layouts/DefaultLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    component: DefaultLayout,
    path: "/default-layout",
    children: [
      {
        path: "/",
        name: "home",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: () => import("../views/HomeView.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: () => import("../views/ContactView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
