import { SocialMediaLinkInterface } from "@/interfaces/SocialMediaLinkInterface";

export const useSocialMedia = () => {
  const socialMedia: SocialMediaLinkInterface[] = [
    {
      fa: "bi-linkedin",
      to: "https://www.linkedin.com/company/aicharge/about/",
    },
    {
      fa: "bi-facebook",
      to: "https://www.facebook.com/aichargeservice/",
    },
    {
      fa: "bi-instagram",
      to: "https://www.instagram.com/aicharge",
    },
    {
      fa: "bi-tiktok",
      to: "https://www.tiktok.com/@aicharge",
    },
  ];

  return {
    socialMedia,
  };
};
