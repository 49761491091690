import { LocaleInterface } from "@/interfaces/LocaleInterface";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export const useLanguageSwitcher = () => {
  const { locale } = useI18n();

  const availableLocales: LocaleInterface[] = [
    {
      flag: require("@/assets/images/flags/mk.svg"),
      value: "mk",
    },
    {
      flag: require("@/assets/images/flags/en.svg"),
      value: "en",
    },
    {
      flag: require("@/assets/images/flags/al.svg"),
      value: "al",
    },
  ];

  const selectedLocale = computed(() => {
    switch (locale.value) {
      case "mk":
        return {
          flag: require(`@/assets/images/flags/${locale.value}.svg`),
          value: locale.value,
        };
      case "al":
        return {
          flag: require(`@/assets/images/flags/${locale.value}.svg`),
          value: locale.value,
        };
      default:
        return {
          flag: require(`@/assets/images/flags/en.svg`),
          value: "en",
        };
    }
  });

  const changeLocale = (l: string) => {
    localStorage.setItem("selectedLanguage", l);
    locale.value = l;
  };

  return {
    changeLocale,
    availableLocales,
    selectedLocale,
  };
};
