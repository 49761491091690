import "./assets/scss/main.scss";
import "bootstrap";

import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createMetaManager } from "vue-meta";
const metaManager = createMetaManager();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import en from "./locales/en.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mk from "./locales/mk.json";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import al from "./locales/al.json";
import axios from "axios";

const i18n = createI18n({
  messages: { en, mk, al },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  locale: localStorage.getItem("selectedLanguage"),
  fallbackLocale: "mk",
  legacy: false,
});
const app = createApp(App);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

app.use(router);
app.use(i18n);
app.use(store);
app.use(metaManager);

app.mount("#app");
