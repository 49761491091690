<template>
  <div class="container py-5">
    <div class="row mt-4 text-center text-md-start">
      <div class="col-md-6 col-lg-3 mb-4">
        <div
          class="d-flex justify-content-center align-items-center justify-content-md-start align-items-md-start mb-4"
        >
          <img
            src="../../assets/images/logo.svg"
            class="black-logo"
            alt="Logo"
          />
        </div>
        <ParagraphOne>
          {{ t("components.app-footer.footer-text") }}
        </ParagraphOne>
      </div>
      <div class="col-md-3 offset-lg-3 mb-4">
        <ParagraphOne class="fw-bold">AiCharge</ParagraphOne>
        <ul class="list-unstyled">
          <li v-for="(link, i) in legalPagesLinks" :key="i" class="mb-2">
            <a
              target="_blank"
              :href="link.to"
              class="text-decoration-none text-black"
            >
              {{ t(link.name) }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 text-center mb-4">
        <p class="fw-bold">{{ t("components.app-footer.social-media") }}</p>
        <div class="d-flex flex-row justify-content-center align-items-center">
          <div v-for="(media, i) in socialMedia" :key="i" class="mx-2">
            <a
              style="width: 40px; height: 40px"
              :href="media.to"
              target="_blank"
              class="text-decoration-none d-block bg-primary rounded-circle d-flex align-items-center justify-content-center"
            >
              <i class="bi fs-4 text-white p-2" :class="media.fa"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-wrap gap-5 align-items-center justify-content-center mb-3"
    >
      <div
        v-for="image in images"
        :key="image.src"
        class="border border-1 border-light"
      >
        <img :src="image.src" :alt="image.alt" height="30" />
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-center justify-content-center gap-1 fs-10px"
    >
      <div>{{ t("components.app-footer.company-details.copyright") }}</div>
      <div>{{ t("components.app-footer.company-details.address") }}</div>
      <div>{{ t("components.app-footer.company-details.ems-edb") }}</div>
      <div>
        {{ t("components.app-footer.company-details.email") }}:
        aicharge@email.com |
        {{ t("components.app-footer.company-details.phone") }}: +389 78 538 988
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSocialMedia } from "@/composables/socialMedia";
import ParagraphOne from "@/components/base-components/paragraphs/ParagraphOne.vue";
import { useNavigationLinks } from "@/composables/navigationLinks";
import { useI18n } from "vue-i18n";

const { legalPagesLinks } = useNavigationLinks();
const { socialMedia } = useSocialMedia();

const { t } = useI18n();

const images = [
  {
    src: require("../../assets/images/card-logos/american-express.png"),
    alt: "American Express",
  },
  {
    src: require("../../assets/images/card-logos/diners-club.png"),
    alt: "Diners Club",
  },
  {
    src: require("../../assets/images/card-logos/maestro.png"),
    alt: "Maestro",
  },
  {
    src: require("../../assets/images/card-logos/visa.png"),
    alt: "Visa",
  },
  {
    src: require("../../assets/images/card-logos/mastercard.png"),
    alt: "Mastercard",
  },
];
</script>

<style scoped>
.black-logo {
  filter: invert(100%);
}

.fs-10px {
  font-size: 10px;
}
</style>
